import React, {  useEffect, useState } from "react";
import { Button, Row, Tabs, Table, notification, Modal, Typography, Col, DatePicker, Form, Select } from "antd";
import { useSelector,useDispatch} from 'react-redux';
import '../../../node_modules/moment/locale/tr';
import locale from '../../../node_modules/antd/es/date-picker/locale/tr_TR';
import moment from "moment";
import { FileOutlined, CheckCircleOutlined,ExclamationCircleOutlined} from "@ant-design/icons";
import readXlsxFile from "read-excel-file";
import {  convertFloatDotSeperated } from "../../helpers/common";
import {  getBuyersListApi, getUploadeMustahsilApi, uploadXmleMustahsilApi} from "../../services/buyerApi";
import UploadBoxFarmer from "./UploadBoxFarmer";
import { Link } from 'react-router-dom';
import {setBuyersListData} from "../../reducers/buyerSlice"


const { TabPane } = Tabs;
const { Text } = Typography;

const BuyerUploadeMustahsil = () => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false); 
  const [files, setFiles] = useState<any>([]);
  const [fileType, setFileType] = useState<any>('');
  const [isFile, setIsFile] = useState<File | null>(null);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [dataActive, setDataActive,] = useState(false);
  const [xmlData, setXmlData] = useState<any>([]);
  const [notInvoiceValid, setNotInvoiceValid] = useState<any>([
    {
      mesaj : [],
      fileName:''
    }
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [called, setCalled] = useState(false); 
  const [multiDate, setMultiDate] = useState('');
  const [dataMustahsil, setDataMustahsil] = useState<any>([]);

  const [selectBuyerId, setSelectBuyerId] = useState<any>('');
  const [selectBuyerTitle, setSelectBuyerTitle] = useState<any>('');
  const {buyerList} = useSelector((state: any) => state.buyer);

  const getBuyersList =  async () => {
    try {
      const response: any = await getBuyersListApi();
      if (response) {
        const filteredBuyers = response.buyers.filter((buyer:any) => buyer.typeCode === "CIFTCI_ALICISI");
        dispatch(setBuyersListData(filteredBuyers));
      }
    } catch (error: any) {
      console.log(error);
    }
  }
  
  const handleInput = (getBuyerId:any) => {
    setSelectBuyerId(getBuyerId)
    setSelectBuyerTitle(
      buyerList.filter((item:any) => item.id === getBuyerId)
    )
  }

  const removeFile = (index: any) => {
    files.length === 1 ? setFiles([]) : files.splice(index, 1);
    files.length > 1 && setFiles([...files]);
    (files.length === 1 || files.length === 0) && setIsFile(null);
    setTableData([]);
    setXmlData([]);
    setData([]);
    setNotInvoiceValid([
      {
        mesaj : [],
        fileName:''
      }
    ]);
    setDataActive(false);
    setIsModalOpen(false); 
    setCalled(false);
    setFiles([]);
  };

  const onUploadFile = async (f: File) => {
    const isXls = f.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || f.type === 'xlsx';
    if(isXls){
      setFiles('')
      try {
        setIsFile(f);
        files.push({file: f, isSend: false});
        setFiles(files);  
        const rows = await readXlsxFile(f);
        rows.shift(); // headers
        setTableData(rows);
      } catch {
        removeFile(0);
      }
    }else{
      notification.error({
        message: "Yalnızca .xls ve .xlsx uzantılı dosya yükleyebilirsiniz!",
      });
    }
  };

  const uploadXmlFile = async (file: any) => {
    if (file) {
      setIsLoadingUpload(true);
      setFileType('text/xml');
      try {
        let invoices = [];
        invoices = xmlData.filter((dataItem:any) => dataItem !== false).map((item:any) => {
          return {
            fileName:item.fileName,
            invoiceDate:item.invoiceDate,
            serialNumber:item.serialNumber,
            orderNumber:item.orderNumber,
            invoiceOwnerTaxNumber:item.invoiceOwnerTaxNumber,
            currency:item.currency,
            invoiceTotal:item.invoiceTotal,
            invoiceTerm:item.invoiceTerm,
            assigmentAmount:item.assigmentAmount,
            hashCode:item.hashCode,
            eDocumentType:item.eDocumentType,
            taxFreeAmount:item.taxFreeAmount,
            buyerTaxNumber:item.buyerTaxNumber,
            isInvoiceCustomerValid:true,
            isEProducer:true
          }
        })
  
        const response: any = await uploadXmleMustahsilApi(selectBuyerId, invoices);
        if (response) {
          setData(response);
          setDataActive(true) 
          files[0] = {...files[0], isSend: true};
          setFiles(files);
          setIsLoadingUpload(false);
          setIsModalVisible(false);
          notification.success({
            message: "İşlem Başarılı",
            description: response.message,
          });
        }
      } catch (error: any) {
        setIsLoadingUpload(false);
        setIsModalVisible(false);
        setIsLoadingUpload(false);
        removeFile(0);
        console.log(error)
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onUploadFileXmlMustahsil = async (dataMustahsil:any) => {
    let result:any = [];
    result = data && data.filter((item:any) => {
    return item.type !== 'text/xml'
    })
    if(result.length > 0 ){
      notification.error({
        message: "Yalnızca .xml uzantılı dosya yükleyebilirsiniz!",
      });
    }else{
      getUploadFileXmlMustahsil(dataMustahsil)
    }
  };

  const getUploadFileXmlMustahsil = async (data:any) => {
    const formData = new FormData();
    setFiles(data);
    data && data.map((item:any, index:any) => {
     return(
        formData.append("files", data[index]) 
      )
     })
      try {
        setIsLoadingUpload(true);
        const response: any = await getUploadeMustahsilApi(selectBuyerId,encodeURIComponent(selectBuyerTitle[0].title), formData);
        setXmlData( 
          response?.invoicesAndReasons.map((data:any) => {
            if(data.item2.length === 0  ){
            return  data.item1
            }
            return false
          })
        );
        const validData = response?.invoicesAndReasons.map((data:any) => {
          if(data.item2.length !== 0){
            setIsModalOpen(true)
            return  (
              {
                mesaj: data.item2,
                fileName: data.item1.fileName
              }
            )
          }
          return false
        });

        setNotInvoiceValid( validData )
    
        setIsFile(data[0]);
        if (response) {
            setCalled(true)
            setIsLoadingUpload(false);
            setIsModalVisible(false); 
        }
      } catch(e:any) {
        setIsLoadingUpload(false);
        removeFile(0);

        if(!e.response){
          notification.warning({
            message: `Yüklemiş olduğunuz dosyaların toplam boyutu 15MB'ı geçmemelidir.”` ,
          });
        }
       
        if(e.response.data.Status === 400 && e.response.data.Type.includes('InvalidXmlFormatException') ){
          notification.warning({
              message: ` ${e.response.data.Title}` ,
          });
        }
      } 
  }

  const onEdit = (e: string, action: 'add' | 'remove') => {
    if (action === 'add') {
      console.log(multiDate)
      // add();
    } else {
      // console.log(e);
      removeFile(e);
      setSelectBuyerId('')
    }
  };
  

  const downloadSample =  () => {
    if(data){
      const link = document.createElement('a');
      link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(data.excelReportFileBase64);
      link.setAttribute('download', 'Hata Raporu - Hata Raporu.xlsx');
        
      link.style.display = 'none';
      document.body.appendChild(link);      
      link.click();      
      document.body.removeChild(link);
    }else{
      console.log('')
    }    
  };

const  disabledDate = (current: any) => {  
    return (
      current < Date.now() ||
      (new Date(current).getDay() === 0 ||
      new Date(current).getDay() === 6)
  );
}

 const  handleDatePickerChange = (e:any) => {
  xmlData.filter((item:any) => {
    if(item !== false){
      if(item.invoiceNumber === e.target.row.invoiceNumber ){
        item.invoiceTerm = (e.target.value).toISOString()
      }
      return true
    }
   return true
  })
 } 

 const  handleMultiDateChange = (e:any) => {
  setMultiDate(e.target.value)
  xmlData.filter((dataItem:any) => dataItem !== false).map((item:any) => {
    return(
      item.invoiceTerm = (e.target.value).toISOString()
    )
  })
 } 

 const xmlFunction = () => {
  setCalled(false)
 }

 useEffect(()=>{
  if(dataMustahsil.length !== 0){
    if(!called){
      onUploadFileXmlMustahsil(dataMustahsil);
      setCalled(true);
    }
}
    // eslint-disable-next-line react-hooks/exhaustive-deps
 },[dataMustahsil])

 useEffect(() => {
  getBuyersList();
   // eslint-disable-next-line react-hooks/exhaustive-deps   
},[])

  const xmlCols: any = [
    {
      title: "E-Müstahsiller Alacaklısı TCKN/VKN",
      dataIndex: "invoiceOwnerTaxNumber",
      key: "invoiceOwnerTaxNumber",
      width: 160,
      fixed: 'left',
    },
    {
      title: "E-Müstahsiller No",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: 180,
    },
    {
      title: "E-Müstahsiller Tarihi",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (value: string) => moment(value).format("DD-MM-YYYY"),
      align: 'center'
    },
    {
      title: "E-Müstahsiller Vadesi (Alıcının Ödemek İstediği Tarih)",
      dataIndex: "invoiceTerm",
      key: "invoiceTerm",
      render: (value: string, row:any) =>  <DatePicker 
          placeholder={moment(value).format("DD-MM-YYYY")}  
          locale={locale} 
          format='DD/MM/YYYY'
          onChange={(value) =>
            handleDatePickerChange({ target: { value, name: "date", row } })
          }
          disabledDate={disabledDate}
          disabled={dataActive} 
          showToday={false}
          />,
      align: 'center'
    },
    {
      title: "Vergiler Dahil E-Müstahsiller Tutarı",
      dataIndex: "invoiceTotal",
      key: "invoiceTotal",
      render: convertFloatDotSeperated,
      align: 'center'
    },
    {
      title: "Temlik Edilecek Tutar",
      dataIndex: "assigmentAmount",
      key: "assigmentAmount",
      render: convertFloatDotSeperated,
      align: 'center'
    },
    {
      title: "Vergi Öncesi Tutar",
      dataIndex: "taxFreeAmount",
      key: "taxFreeAmount",
      render: convertFloatDotSeperated,
      align: 'center'
    },
    {
      title: "Elektronik Belge Tipi (E-Arşiv:0, E-Fatura:1, Kağıt:2)",
      dataIndex: "eDocumentType",
      key: "eDocumentType",
      align: 'center'
    },
    {
      title: "E-Müstahsiller Hash Kodu",
      dataIndex: "hashCode",
      key: "hashCode",
      align: 'center'
    },
  ];

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Alıcı Adına E-Müstahsil Yükleme</h3>
        </Col>
      </Row>
      <Modal className="mustahsil-modal" title="Göndermek istediğinizden emin misiniz?" footer={null} open={isModalVisible} onOk={() => setIsModalVisible(true)} onCancel={() => setIsModalVisible(false)}>
        <div style={{display: 'grid', }}>
          <Text>E-Müstahsillerinizi gönderdiğinizde, ilgili tedarikçiler bunları iskonto ettirebilecektir.</Text>
          <Button
            className="popup-send-btn"
            type="default"
            htmlType="submit"
            size="large"
            onClick={() =>  uploadXmlFile(isFile) }
            loading={isLoadingUpload}
            block
          >
            Gönder
          </Button>
        </div>
      </Modal>
      {!isFile ? (
        <>
         <Row style={{paddingTop:'0'}}>
          <Col md={15} xl={15} xs={24}>
          <Form
            
              name="user-update"
              className="default-form"
              labelCol={{
              span: 10,
              }}
              wrapperCol={{
                span: 14,
              }}
              layout="vertical"
              initialValues={{
                size: 'large',
              }}
            
              size={'large'}
              style={{
                maxWidth: 600,
              }}
          >
          <Form.Item 
                label="Alıcı Seçiniz:"
                name="buyerId"
                rules={[
                  {
                      required: true,
                      message: 'Lütfen finans kurumu seçiniz.',
                  },
                ]}
                >
                <Select onChange={(e:any) => handleInput(e)}>
                  {
                  buyerList && buyerList.map((item:any, index:any) => {
                      return(
                        <Select.Option key={index} value={item.id} >{item.title}</Select.Option>
                      )
                    })
                  }
                
                </Select>
              </Form.Item>
              </Form>
          </Col>
        </Row>
       
        <UploadBoxFarmer 
            fileType={'text/xml-mustahsil'}  
            setFileType={setFileType} 
            isLoadingUpload={isLoadingUpload} 
            removeFile={removeFile} 
            onUploadFile={onUploadFile} 
            xmlFunction={xmlFunction}  
            setDataMustahsil={setDataMustahsil}  
            selectBuyerId={selectBuyerId}
            />
        </>
       
      ) : (
        <>
        { dataActive && (
          <>
           <Row>
          <Col>
          {files?.length > 0 && <p className="title"> En son yüklenmiş olan {fileType === 'excel_dosya' ? files.name : 'XML Dosya' } E-Müstahsiller Özeti</p>} 
          </Col>
        </Row>
        <Row style={{marginBottom:'20px', paddingTop:'0'}}>
          <Col  span={24} style={{ margin: "0px auto" }} >
            <div className="sum-information sum-information-muh">
               <div className="box box1">
                  <div className="icon-box" style={{padding:'0'}}>
                    <FileOutlined />
                  </div>
                  <span className="txt3">Toplam</span>
                  <span className="txt5">{data.totalLoadedInvoiceCount} Adet</span>
                </div>
                <div className="box box3">
                  <div className="icon-box" style={{padding:'0'}}>
                    <CheckCircleOutlined />
                  </div>
                  <span className="txt3">Başarılı</span>
                  <span className="txt5">{data.totalValidInvoiceCount} Adet</span>
                </div>
                <div className="box box4">
                  <div className="icon-box" style={{padding:'0'}}>
                    <ExclamationCircleOutlined />
                  </div>
                  <span className="txt3">Hatalı</span>
                  <span className="txt5">{data.totalInvalidInvoiceCount} Adet</span>
                  <Link  to=''  className={`link ${data.totalInvalidInvoiceCount !== 0 ? '' : 'isDisable'}`} 
                    onClick={downloadSample}>Hata Raporu</Link>
                </div>
                
                      
            </div>
          </Col>
        </Row>
          </>
          )
        }
       
        <Row style={{  width: '100%' }}>
        
          <Tabs
            type="editable-card"
            className="upload-tabs upload-tabs-x"
            defaultActiveKey='0'
            onEdit={(e: any, action) => onEdit(e, action)}
            hideAdd
            tabBarExtraContent={
              files?.length > 0  ? 
              <Row>
                <Button
                  // style={{ height: "46.5px", marginTop: 1, marginLeft: "auto" }}
                  // className="tab-send-btn"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  onClick={() => setIsModalVisible(true)}
                  loading={isLoadingUpload}
                  block
                >
                  Gönder
                </Button>
              </Row> : null
            }
          >
            
              <TabPane
                tab={<p className="upload-tab-title">{fileType === 'excel_dosya' ? files[0]?.file?.name : 'XML Dosya Yüklenilmesi'}</p>}
                key={1}
              >
                { !!tableData?.length ? 
                  (              
                    <Table
                      dataSource={tableData}
                      pagination={false}
                      className={"table xls-table"}
                      scroll={{ x: 1500 }}
                      columns={[
                        "E-Müstahsiller Alacaklısı TCKN/VKN",
                        "E-Müstahsiller No",
                        "E-Müstahsiller Tarihi",
                        "E-Müstahsiller Vadesi (Alıcının Ödemek İstediği Tarih)",
                        "Vergiler Dahil E-Müstahsiller Tutarı",
                        "Temlik Edilecek Tutar",
                        "Vergi Öncesi Tutar",
                        "Elektronik Belge Tipi (E-Arşiv:0, E-Fatura:1, Kağıt:2)",
                        "E-Müstahsiller Hash Kodu",
                      ].map((label, idx) => ({
                        dataIndex: idx,
                        title: label,
                        align:'center',
                        render: (value: string) =>
                          idx === 10 
                            ? value ? 'E' : 'H' 
                            : idx === 2 || idx === 3
                              ? moment(value).format("DD-MM-YYYY")
                              : value,
                      }))}
                      style={{ padding: '0px 0px 10px'}}
                    />
                  )
                  : 
                  (   
                    <>
                      <Row style={{marginBottom:'20px'}}>
                        <Col>
                        <strong style={{paddingRight:'20px'}}>Tüm E-Müstahsiller Vadesini Değiştir (Ödemek İstediğiniz Tarih)</strong>
                        <DatePicker 
                          locale={locale} 
                          format='DD/MM/YYYY'
                          onChange={(value) =>
                            handleMultiDateChange({ target: { value, name: "date" } })
                          }
                          disabledDate={disabledDate}
                          disabled={dataActive} 
                          showToday={false}
                          />
                        </Col>
                      </Row>
                      <Table
                      dataSource={xmlData && xmlData.filter((item:any) => item !== false)}
                      pagination={false}
                      className={"table xls-table"}
                      scroll={{ x: 1500 }}
                      columns={xmlCols}
                      style={{ padding: '0px 0px 10px'}}
                    /> 
                    </>               
                  )
                  }
               
              </TabPane>
          </Tabs>
        </Row>
        {
           <Modal 
           className="notInvoiceValid-modal" 
           title="Geçersiz E-Müstahsiller" 
           open={isModalOpen} onCancel={handleCancel}   
           footer={false}>
           {
            notInvoiceValid.filter((item:any) => item !== false).map((data:any) => {
              return(
                <div className="upload-invoices-modal">
                     <h4>{data.fileName}</h4>
                      {
                        data.mesaj.map((i:any) => <p><b>-</b> {i}</p>)
                      }
                </div>
              )
            })
           }
         
        </Modal>
        }
        </>

      )}
    </main>
    
  );
};

export default BuyerUploadeMustahsil;
