import React, { useState, useEffect } from "react";
import moment from "moment";
import { Row, Table,  Col, DatePicker, Button, notification, Select, Form, Spin} from "antd";
import {convertCurrencyTl} from "../../helpers/common";
import {  getBuyersListApi, getSupplierListApi, getBuyerRiskHistoryApi, getXlsxDonwloadApiApi} from "../../services/buyerApi";
import 'dayjs/locale/tr'
import '../../../node_modules/moment/locale/tr';
import locale from '../../../node_modules/antd/es/date-picker/locale/tr_TR';


function SupplierRiskMovements() {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [buyerRiskHistories, setBuyerRiskHistories] = useState<any>([]); 
  const [show, setShow] = useState(false);
  const [title,setTitle] = useState('');
  const [supplierTitle,setSupplierTitle] = useState<any>([]);
  const [buyerTitle, setBuyerTitle] = useState<any>();
  const [buyerId, setBuyerId] = useState('');
  const [supplierId, setSupplierId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [form] = Form.useForm();

  const getBuyerList =  async () => {
    try {
      const response: any = await getBuyersListApi();
      if (response) {
        setBuyerTitle(response?.buyers);
      }
    } catch (error: any) {
      // console.log(error);
    }
  }

  const getSupplierList =  async (buyerId:any) => {
    try {
      setLoading(true)
      const response: any = await getSupplierListApi(buyerId);
      if (response) {
        setSupplierTitle(response?.suppliers)
        setLoading(false)
      }
    } catch (error: any) {
      // console.log(error);
    }
  }

  const getBuyerRiskHistory =  async (startDate:string, finishDate: string) => {
    try {
      setLoading(true)
      const response: any = await getBuyerRiskHistoryApi(buyerId, supplierId, startDate, finishDate, pageNumber, pageSize);
      if (response) {
        setBuyerRiskHistories(response.buyerRiskHistories.data);
        setTotalDataCount(response.buyerRiskHistories.totalDataCount)
        setLoading(false)
      }
    } catch (e: any) {
      setLoading(false)
      console.log(e)
    }
  }

  const  disabledDate = (current: any) => {  
    return (
      (new Date(current).getDay() === 0 ||
      new Date(current).getDay() === 6)
    );
  }

  const handleChangeBuyerList = async (value: string) => {
    setBuyerId(value);
    setSupplierTitle([]);
    setShow(false);
    form.resetFields();
    if(value){
      getSupplierList(value)
    }
  };

  const handleChangeSupplierList = async (value: string) => {
    setSupplierId(value);
    const supTitle:any [] = supplierTitle.filter((item:any) => item.id === value)
    setTitle(supTitle[0].supplierTitle)
    form.resetFields();
    setShow(false);
  };

  const onchangeValue = async (values: any) => { 
    setStartDate(values.startDate !== undefined && values.startDate !== null && values.startDate !== ''  ? values.startDate.toISOString().replace("Z", "") : startDate);
    setEndDate(values.endDate !== undefined && values.endDate !== null && values.endDate !== ''  ? values.endDate.toISOString().replace("Z", "") : endDate)
    if(supplierId !== ''){
      if(values.startDate !== undefined && values.startDate !== null && values.startDate !== '' ){
        getBuyerRiskHistory( values.startDate.toISOString().replace("Z", ""),  values.endDate !== undefined && values.endDate !== null && values.endDate !== '' ? values.endDate.toISOString().replace("Z", ""): '');
        setShow(true);
      }else{
        notification.warning({
          message: `Başlangınç tarihi zorunlu seçilmeli.`,
        });
      }
    }else{
      notification.warning({
        message: `Tedarikçi seçimi yapmalısınız.`,
      });
    }
  };

  const xlsxDonwload = async () => {
    setLoading(true)
    try{
      const response:any = await getXlsxDonwloadApiApi(buyerId, supplierId, startDate, endDate, pageNumber, pageSize);
      if (response) {
        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ${encodeURIComponent(response?.xlsxFileReport)}` ;
        link.setAttribute('download', `${title} Tedarikçi Hareketleri.xlsx`); 
      
        link.style.display = 'none';
        document.body.appendChild(link);      
        link.click();      
        document.body.removeChild(link);
        setLoading(false)
      }
    }catch(e:any){ }
  }

  const tableCols: any = [
    {
      title: "İşlem Tarihi",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 150,
      fixed: 'left',
      render: (value: string) => {
        return moment(value).format("DD-MM-YYYY");
       }
    },
    {
      title: "Kredi Kullanımı",
      key: "transactionType",
      align: 'center',
      width: 150,
      render: ( row:any) => {
        return row.transactionType === 1 ? `${convertCurrencyTl(row.transactionAmount)} `  : '';
       }
    },
    {
      title: "Fatura Tahsilatı",
      key: "transactionType",
      align: 'center',
      width: 150,
      render: (row: any) => {
        return row.transactionType === 2 ? `${convertCurrencyTl(row.transactionAmount)} ` : '';
       }
    },
    {
      title: "Risk Bakiyesi",
      dataIndex: "risk",
      key: "risk",
      align: 'center',
      width: 150,
      render: (value:any) => {
        return `${convertCurrencyTl(value)} ` 
       }
    },
    {
      title: "Açıklama",
      dataIndex: "description",
      key: "description",
      align: 'left',
    },
  ];
  
  const handleShowSize = (current: any, size: any) => {
    setPageNumber(current);
    setPageSize(size);
  };

  useEffect(() => {
    if(supplierId){
      getBuyerRiskHistory( startDate, endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize]);

  useEffect(() => {
    getBuyerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <main>
      <Row style={{ marginTop: 30 }}>
        <Col span={24}>
          <h3 className="page-title"> Tedarikçi Risk Hareketleri</h3>
        </Col>
      </Row>
      <Row style={{ paddingBottom: '0' }} >
          <Col >
            <p>Lütfen haraketlerini görüntülemek istediğiniz alıcıyı seçiniz.</p>
            <div style={{textAlign:'left'}}>
              <Select
                defaultValue="Lütfen seçim yapınız."
                style={{ width: 400 }}
                onChange={handleChangeBuyerList}
                options={ buyerTitle && buyerTitle.map((item:any) => {
                  return(
                      { value: item.id, label: item.title}
                    )
                })
                }
              />
            </div> 
          </Col>
      </Row>
      {
         supplierTitle.length > 0 ? 
        (
          <Spin spinning={loading} >
              <Row style={{ marginBottom: '30px' }} >
                <Col>
                  <p>Lütfen haraketlerini görüntülemek istediğiniz tedarikçiyi seçiniz.</p>
                  <div style={{ textAlign: 'left' }}>
                    <Select
                      defaultValue="Lütfen seçim yapınız."
                      style={{ width: 400 }}
                      onChange={handleChangeSupplierList}
                      options={supplierTitle && supplierTitle.map((item: any) => {
                        return (
                          { value: item.id, label: item.supplierTitle }
                        )
                      })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ marginBottom: '30px' }}>
                <Col >
                  <Form
                    form={form}
                    name="filter-box"
                    onFinish={onchangeValue}
                    autoComplete="off"
                    labelCol={{
                      span: 8,
                    }}
                    size="large"
                    style={{
                      minWidth: 400,
                    }}
                  >
                    <div className='filter-row suppliers-movie'>
                      <div>
                        <span style={{ display: 'flex', alignItems: 'center', paddingLeft: '1px' }}>Başlangıç Tarihi
                        <pre style={{ marginBottom: '0', color: '#f00', fontSize: '11px', marginTop:'0' }}> *</pre></span>
                        <Form.Item
                          name='startDate'
                        >
                          <DatePicker locale={locale} format='DD/MM/YYYY' placeholder='Tarih Seçiniz' disabledDate={disabledDate} />
                        </Form.Item>
                      </div>
                      <div>
                        <span style={{ paddingLeft: '1px' }}>Bitiş Tarihi</span>
                        <Form.Item
                          name='endDate'
                        >
                          <DatePicker locale={locale} format='DD/MM/YYYY' placeholder='Tarih Seçiniz' disabledDate={disabledDate} />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                          >Filtrele
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>  
          </Spin>
        ): ''
      }
      {
        show ?
        (
          <>
            <Row  style={{justifyContent:'end', marginBottom:'20px'}}>
              <Button type="primary" size="large" onClick={xlsxDonwload} loading={loading} disabled={buyerRiskHistories.length < 1}>Excel İndir</Button>
            </Row>
            <Row>
            <Col span={24} style={{ margin: "0px auto" }}>
                <Table
                  rowKey="invoiceNumber"
                  loading={loading}
                  dataSource={buyerRiskHistories}
                  columns={tableCols}
                  
                  pagination={{
                    position: ["topRight", "none" as any],
                    showLessItems: true,
                    showSizeChanger: true,
                    pageSize,
                    total: totalDataCount,
                    onChange: handleShowSize,
                    
                  }}
                  scroll={{
                    x: 1200,
                  }}
                />
              
            </Col>
            </Row>
          </>
        ):
        (
          ""
        )
      }
      
    </main>
  );
}

export default SupplierRiskMovements;
