import {apiSupplier} from '../services/apis';

const getDiscountsApi = async (psize:any,page:any,searchQuery:any, searchFinancial:any): Promise<Object> => {
  return searchQuery || searchFinancial ?
   await apiSupplier.get(`admin/discounts?pageNumber=${page}&pageSize=${psize}&searchQuery=${searchQuery}&${searchFinancial}`,
    {}
  ):
  await apiSupplier.get(`admin/discounts?pageNumber=${page}&pageSize=${psize}`,
    {}
  )
};

const getSupplierDatas = async (filters: string, page:any,psize:any): Promise<Object> => {
  return await apiSupplier.get(`admin/suppliers?pageNumber=${page}&pageSize=${psize}${filters ? `${filters}`:''}`,
    {}
  );
};


const deleteSupplierId = async (supId: any): Promise<Object> => {
  return await apiSupplier.delete(`admin/suppliers?ids=${supId}`,
    {}
  );
};

const getTxnHistoryByTaxIdApi = async (id: any): Promise<Object> => {
  return await apiSupplier.get(`admin/discounts/${id}`,
    {}
  );
};

const getImportTemplate = async (discountId: any): Promise<any> => {
  return await apiSupplier.get(`admin/discounts/${discountId}/conveyance`,
    {}
  );
};

const getSuppleirDetailApi = async (discountId: any): Promise<any> => {
  return await apiSupplier.get(`admin/suppliers/${discountId}`,
    {}
  );
};

const getSupplierInfoApi = async (supplierId: any): Promise<any> => {
  return await apiSupplier.get(`admin/suppliers/${supplierId}`,
    {}
  );
};

const updateSupplierApi = async (supplierId:any, newData: any): Promise<Object> => {
  return await apiSupplier.put(`admin/suppliers/${supplierId}`, newData, {} );
};

const uploadDoc = async (payload: any): Promise<Object> => {
  const title = payload.supplierTitle;
  const { formData } = payload;  
  return (
    await apiSupplier.post(`admin/suppliers/${payload.supplierId}/documents?document-type-id=${payload.documentTypeId}`,
    formData,  { headers: {'x-supplier-title': title }} )
  );
};

const getDocsData = async (supplierId: any): Promise<any> => {
  return await apiSupplier.get(`suppliers/${supplierId}/documents`,
    {}
  );
};

const getDocType = async (): Promise<any> => {
  return await apiSupplier.get(`/document-types`,
    {}
  );
};

const deleteDocs = async (supplierId: any, documentTypeId:any ): Promise<Object> => {
  return await apiSupplier.delete(`admin/suppliers/${supplierId}/documents/${documentTypeId}`,
 {}
  );
};

const getDocDownload = async (supplierId:any, docId:any, name:any): Promise<any> => {
  return await apiSupplier.downloadFile(`/suppliers/${supplierId}/documents/${docId}`, name
  );
};


const getFinancialInstitutionApi = async (): Promise<Object> => {
  return await apiSupplier.get(`admin/suppliers/financial-institution-names`,
    {}
  );
};

export {
  getDiscountsApi,
  getSupplierDatas,
  deleteSupplierId,
  getTxnHistoryByTaxIdApi,
  getImportTemplate,
  getSuppleirDetailApi,
  getSupplierInfoApi,
  updateSupplierApi,
  uploadDoc,
  getDocsData,
  getDocType,
  deleteDocs,
  getDocDownload,
  getFinancialInstitutionApi
};
