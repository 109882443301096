export class LocalStorageService {
    static authTokenKey: string = 'admin-auth';
    static authName: string = 'buyer-name';
    static authHint: string = 'buyer-hint';
    static activeRow: string = 'active-row';
    static province: string = 'province';
    static userRole: string = 'user-role';

    static getAuthToken() {
        return  localStorage.getItem(this.authTokenKey);
    }

    static setAuthToken(token?: string | any) {
        localStorage.setItem(this.authTokenKey, token);
    }

    static setName(name?: string | any) {
        localStorage.setItem(this.authName, name);
    }

    static getName() {
        return localStorage.getItem(this.authName);
    }

    static setIsHint(isHint?: string | any) {
        localStorage.setItem(this.authHint, isHint);
    }

    static getIsHint() {
        return localStorage.getItem(this.authHint);
    }

    static removeAuthToken() {
        localStorage.removeItem(this.authTokenKey);
    }

    static removeAuthName() {
        localStorage.removeItem(this.authName);
    }
    static setActiveRow(data?: string | any) {
        localStorage.setItem(this.activeRow, data);
    }
    static getActiveRow() {
        return  localStorage.getItem(this.activeRow);
    }
    static removeActiveRow() {
        localStorage.removeItem(this.activeRow);
    }
    static setProvince(data?: string | any) {
        localStorage.setItem(this.province, data);
    }
    static getProvince() {
        return localStorage.getItem(this.province);
    }
    static removeProvince() {
        localStorage.removeItem(this.province);
    }

    static getUserRole() {
        return localStorage.getItem(this.userRole);
    }

    static setUserRole(userRole?: string | any) {
        localStorage.setItem(this.userRole, userRole);
    }
    static removeUserRole() {
        localStorage.removeItem(this.userRole);
    }


}
