import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, notification,Typography, InputNumber, Collapse} from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, PlusCircleOutlined, MinusCircleOutlined,} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import {convertCurrencyTl} from "../../helpers/common";
import { getFarmersLimitDatas, updatedAgriculturistsLimit, getBuyersListApi} from "../../services/buyerApi";
import { LocalStorageService } from "../../helpers/LocalStorage";

function BuyerUserLimitUpdate() {
  const [form] = Form.useForm();
  const location = useLocation();
  const buyerId = location.pathname.split('/')[2];

  const [agriculturistInformations, setAgriculturistInformations] = useState<any>([]); 
  const [buyerDefinitions, setBuyerDefinitions] = useState<any>([]); 
  const [bankLimitTotal, setBankLimitTotal] = useState<any>();
  const [bankRiskTotal, setBankRiskTotal] = useState<any>();
  const [supplierCountTotal, setSupplierCountTotal] = useState<any>();
  const [inputValue, setInputValue] = useState<any>();
  const [buyerTitle, setBuyerTitle] = useState<any>([]);


  const { Text } = Typography;
  const Panel = Collapse.Panel;

  const getBuyerList =  async () => {
    try {
      const response: any = await getBuyersListApi();
      if (response) {
        setBuyerTitle(response.buyers.filter((item:any) => item.id === buyerId));
      }
    } catch (error: any) {
      // console.log(error);
    }
  }

  const fetchAllSuppliers  = async () => {
    try {
      const response: any = await getFarmersLimitDatas(buyerId);
      if (response) {
        setAgriculturistInformations(response?.agriculturistLimits); 
        setBuyerDefinitions(response?.buyerDefinition);
      }
    } catch (error: any) {      
      // console.log(error) 
    }
  }

  const updateFinanceLimit = async (financialInstitutionId: any,  agriculturistId:any) => {  
    const newData = {
      financialInstitutionId: financialInstitutionId,
      agriculturistId:agriculturistId,
      limit:inputValue
    }

    if(inputValue !== '' || inputValue !== undefined){
      try{
        await updatedAgriculturistsLimit(newData, buyerId);    
        notification.success({message: 'Limit başarıyla güncellenmiştir.'});  
        window.location.reload();         
      }catch(e:any){

        if(e.data.Status === 406 && e.data.Type.includes('BuyerLimitExcessException')){
          notification.warning({message: 'Çiftçi Limiti Alıcı Limitinden Fazla Olamaz.'});
        }  
        if(e.data.Status === 406 && e.data.Type.includes('InvalidSupplierLimitException')){
          notification.warning({message: "Çiftçi Limiti 0' dan küçük olamaz" });
        }        
      }     
    }if(inputValue === undefined){
      notification.success({message: 'Güncellenecek limit girmediniz.'});
    }    
  }

  const clearInput = (row:any) =>{
    form.setFieldValue(row.target.id, '');
  }

  useEffect(() => {
    setBankLimitTotal(buyerDefinitions.reduce((a:any,v:any) =>  a = a + v.limit , 0 ))
    setBankRiskTotal(buyerDefinitions.reduce((a:any,v:any) =>  a = a + v.risk , 0 ))  
    setSupplierCountTotal(buyerDefinitions.reduce((a:any,v:any) =>  a = a + v.supplierCount, 0 ))
  }, [buyerDefinitions]);

  const header = (supplierTitle:any,supplierTaxId:any, sumRisk:any, sumLimit:any, isRegistered:any) => {
    return (
      <div className="header" style={{display:'flex'}}>
        <div>{supplierTitle}</div>
        <div>{supplierTaxId}</div>
        <div>{convertCurrencyTl(sumRisk)} </div>
        <div>{convertCurrencyTl(sumLimit)}</div>
        <div>{sumRisk === 0 ? '-' : ((sumRisk * 100)/sumLimit)?.toFixed(2)} % </div>
        <div>{
            isRegistered 
            ? <CheckCircleOutlined style={{color: '#62b61f', fontSize: 22}} /> 
            : <CloseCircleOutlined style={{color: '#cc2c2c', fontSize: 22}} />
            }
        </div>
      </div>
    )
  }

  useEffect(() => {
    getBuyerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerId]);
 

  useEffect(() => {
    fetchAllSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  return (
    <main>
    <Row >
      <Col span={24}>
        <h3 className="page-title"> Alıcı Çiftçi Limit Güncelleme</h3>
      </Col>
    </Row>
    <Row className="margin-bottom">
      <Col span={24}>
        <h3> Alıcı : {buyerTitle && buyerTitle[0]?.title}</h3>
      </Col>
    </Row>
    <Row>
      <Text style={{fontWeight:'600'}}>Toplam limitinizi çiftçileriniz arasında bölüştürün.</Text>       
    </Row>
    <Row>
      <Text style={{marginTop:'15px', fontSize:'20px '}}>
          Banka Risk/Limit Durumu 
        </Text>
    </Row>
    <Row className="limit-header-title">
        <Col span={24}>
            <div className="title buyer-definitions-title">
                <div>Finans Kurumu</div>
                <div>Mevcut Risk</div>
                <div>Limit</div>
                <div>Risk/ <br/> Limit %</div>
                <div>Çiftçi <br/> Adedi</div>
            </div>
            <div className="table-li buyer-definitions-li" style={{marginLeft:'0'}}>
              {
                buyerDefinitions && buyerDefinitions.map((item:any,index:any) => {
                  return (
                    <div key={index}>
                      <div>{item.financialInstitutionName}</div>
                      <div>{convertCurrencyTl(item.risk)} </div>
                      <div>{convertCurrencyTl(item.limit)} </div>
                      <div>{item.risk === 0 ? '-' : ((item.risk * 100)/item.limit)?.toFixed(2)} %</div>
                      <div>{item.supplierCount}</div>                           
                  </div>
                  )
                })
              }            
              
              <div>
                <div>TOPLAM</div>
                <div>{bankRiskTotal && convertCurrencyTl(bankRiskTotal)} </div>
                <div>{bankLimitTotal && convertCurrencyTl(bankLimitTotal)} </div>
                <div>{bankRiskTotal === 0 ? '-' : (bankRiskTotal && bankLimitTotal && (bankRiskTotal * 100)/bankLimitTotal)?.toFixed(2)} %</div>
                <div>{supplierCountTotal && supplierCountTotal}</div>
              </div>
            </div>
        </Col>
      </Row>
    <Row style={{marginTop:'30px'}}>        
      <Text style={{marginTop:'15px', fontSize:'20px '}}>
        Çiftçi Risk/Limit Durumu ve Güncelleme
      </Text>
      <Text style={{marginTop:'15px', fontStyle:'italic'}}><b>"Toplam Limitinizi "</b>Çiftçileriniz arasında uygun gördüğünüz şekilde bölüştürebilirsiniz. Herhangi bir Çiftçiye vereceğiniz limit en çok <b>“Toplam Limitiniz”</b> kadar olabilir. Ancak tüm Çiftçilerinize toplam kullandırımınız <b>“Toplam Limitiniz”</b>i aşamaz.</Text>
    </Row> 
    {/* <Row style={{marginTop:'20px'}}>
        <Col span={10}>
          <Form
             name="filter-box"
            //  onFinish={onchangeValue}
             autoComplete="off"
             labelCol={{
               span: 8,
             }}
             size = "large"
             style={{
               minWidth: 400,
             }}
          >
            <div className='filter-row'>                
              <div>
                <Form.Item name="title" >
                  <Input 
                    placeholder="Tedarikçi Adı veya VKN'sini Seçiniz"
                    style={{minWidth:'450px'}}
                   />
                  </Form.Item>
              </div>               
              <div>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"                 
                  >Ara</Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Col>
    </Row> */}
    <div className="limit-header-title-mobil">
      <Row className="limit-header-title">
        <Col span={24}>
            <div className="title">
                <div>Çiftçi Adı</div>
                <div>VKN No</div>
                <div>Mevcut Risk</div>
                <div>Limit</div>
                <div>Risk/ <br/> Limit %</div>
                <div>Platforma <br/> Kayıt Durumu</div>
            </div>
        </Col>
      </Row>
      <Row >
        <Col span={24}>
          <Collapse 
            accordion 
            // defaultActiveKey={['0']} 
            expandIcon={({ isActive }) => (
              isActive ? <MinusCircleOutlined /> :  <PlusCircleOutlined />
            )}
            className="limit-acc-style" >
            {
              agriculturistInformations && agriculturistInformations.map((item :any,index:any) => {
                return(
                  <Panel 
                    className="acc-row"
                    header={header(
                      item.nameSurname,
                      item.taxNumber,
                      item.risk,
                      item.limit,
                      item.isRegistred,
                    )} 
                    key={index}
                  >
                  <div className="table-li">                   
                    {
                      item.risksAndLimits.map((finance:any, i:any) => {
                        return(
                          <div key={i}>
                            <div></div> 
                            <div>{finance.financialInstitutionName}</div>
                            <div>{convertCurrencyTl(finance.risk)} </div>
                            <div>
                                <Form form={form} className="supplier-limit-input" style={{display:'flex'}}>
                                  <Form.Item 
                                    name={`limit${i}` }
                                    className='hide-arrow'
                                    >
                                    <InputNumber                                        
                                      formatter={(val) => ` ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/\.(?=\d{0,2}$)/g, ",")} 
                                      parser={(val:any) => val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")}
                                      defaultValue={`${convertCurrencyTl(finance.limit)}`}
                                      onChange={(val:any) => setInputValue(val)} 
                                      onClick={(row:any) => clearInput(row)}
                                      className="no-spin input-width"
                                      style={{borderRadius:'8px'}}                 
                                      type='text' 
                                      disabled={LocalStorageService.getUserRole() !== 'SUPERVISOR'}
                                    />           
                                  </Form.Item>
                                <Form.Item>
                                  <Button 
                                    className={`supplier-limit-btn ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}
                                    onClick={() => updateFinanceLimit(
                                      finance.financialInstitutionId, 
                                      item.id
                                      ) } 
                                  >
                                    Güncelle
                                  </Button>
                                </Form.Item>
                              </Form>                                
                            </div>
                            <div>{finance.risk === '0' ? '-' : ((finance.risk * 100)/finance.limit)?.toFixed(2)} % </div>  
                            <div></div>                         
                          </div>
                        )
                      })
                    }
                  </div>                  
                  </Panel>
                )
              })
            }
            </Collapse>
        </Col>       
      </Row>
    </div> 
   
  </main>
  );
};


export default BuyerUserLimitUpdate;
