import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, Input} from "antd";
import { useLocation } from "react-router-dom";
import {  getBuyerDetailApi, updateBuyerLimitApi} from "../../services/buyerApi";
import {convertCurrencyTl} from "../../helpers/common"



const BuyerUserDetail = () => {
  const location = useLocation();
  const buyerId = location.pathname.split('/')[2];
  const [form] = Form.useForm();
  const [buyerDetail, setBuyerDetail] = useState<any>([]);
  const [buyerDefinitions, setBuyerDefinitions] = useState<any>([]);
  const [inputValue, setInputValue] = useState<any>([]);

  const onFinish =  async () => {
    
    const newData = buyerDefinitions && buyerDefinitions.map((item:any, index:any) => {
      return {
        financialInstitutionId : item.financialInstitutionId,
        limit: inputValue[index]
        }
      }).filter((item:any) => {
        if(item.financialInstitutionId !== '' && 
            item.limit !== '' && 
            item.financialInstitutionId !== undefined && 
            item.limit !== undefined)
            {
           return item
        }
        return true
      })
   
      try {
          await updateBuyerLimitApi(buyerId , { financialInstitutionLimits : newData}) ;
          window.location.reload(); 
        } catch (error: any) {
          console.log(error);
        }
  };


const getBuyerDetail =  async () => {
  try {
    const response: any = await getBuyerDetailApi(buyerId);
    if (response) {
      setBuyerDetail(response);
      setBuyerDefinitions(response.buyerDefinitions)
    }
  } catch (error: any) {
    console.log(error);
  }
}


  useEffect(() => {
    getBuyerDetail();
       // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[])


  return (
    <main>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title"> Alıcılar Detay</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
        <Form
              form={form}
              name="user-update"
              className="default-form"
              onFinish={onFinish}
              labelCol={{
              span: 10,
              }}
              wrapperCol={{
                span: 14,
              }}
              layout="vertical"
              initialValues={{
                size: 'large',
              }}
            
              size={'large'}
              style={{
                maxWidth: 600,
              }}
          >
          <div className="detail-row">
              <span>Alıcı Ünvanı :</span>
              <span>{buyerDetail?.buyer?.title}</span>
          </div>
          <div className="detail-column">
              <div className="detail-column-div">
                <span>Alıcı Limiti :</span>
                <span>Alıcı Riski :</span>
                <span>Valör :</span>
              </div>
              {
                buyerDefinitions && buyerDefinitions.map((item:any, index:any) => {
                  return (
                    <div className="detail-form-div buyer-d-detail" key={index} >
                      <div className="detail-form ">
                        <span>{item.financialInstitutionName}</span>
                        <Form.Item 
                          name="buyerLimitUpdate"
                          >
                          <Input 
                            style={{marginBottom:'0'}} 
                            placeholder={convertCurrencyTl(item.limit)}
                            onInput={(e:any) => {
                              setInputValue((prevArr:any) => {
                                const result = [...prevArr];
                                result[index] = e.target.value;
                                return result;
                              });
                            }}
                            />
                        </Form.Item>
                      </div>
                      <span className="risk-span">{convertCurrencyTl(item.risk)}</span>
                      <span className="risk-span">{item.delayValue}</span>
                  </div>
                  )
                })
              }
            
          </div>
          <div className="detail-row">
              <span>Vergi Kimlik No :</span>
              <span>{buyerDetail?.buyer?.taxId}</span>
          </div>
          <div className="detail-row">
              <span>Vergi Dairesi :</span>
              <span>{buyerDetail?.buyer?.taxAdministration}</span>
          </div>
          <div className="detail-row">
              <span>İl :</span>
              <span>{buyerDetail?.buyer?.state}</span>
          </div>
          <div className="detail-row">
              <span>İlçe :</span>
              <span>{buyerDetail?.buyer?.province}</span>
          </div>
          <div className="detail-row">
              <span>Adres :</span>
              <span>{buyerDetail?.buyer?.address}</span>
          </div>
          <Form.Item style={{marginTop:'25px'}}>
              <Button type="primary"  htmlType="submit">Alıcı Limitini Güncelle</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
     
    </main>
  );
};

export default BuyerUserDetail;
